import { Component, Input } from '@angular/core';
import { DeductionInstallements, IDeduction } from '@app/modules/accounting/models/deduction.model';
import { AddEditDeductionsDialogComponent } from '../add-edit-deductions-dialog/add-edit-deductions-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { DeductionsService } from '@app/modules/accounting/services/deductions.service';
import { catchError, throwError } from 'rxjs';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { ConfirmActionDialogComponent } from '@app/modules/shared/components/confirm-action-dialog/confirm-action-dialog.component';

@Component({
  selector: 'deductions-table',
  templateUrl: './deductions-table.component.html',
  styleUrls: ['./deductions-table.component.scss']
})
export class DeductionsTableComponent {
  @Input() dataSource: IDeduction[] = [];
  permissions: any = this.rulesService.UserData[58].data;

  //Filters
  filters: any = {
    issued_by: [],
    driver_name: [],
    division: [],
    unit_no: [],
    description: 'Both',
    total_deductions: {sign: 'More than', value: null},
    no_of_installments: {sign: 'More than', value: null},
    files: 'Both'
  };

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'start', originY: 'bottom'},
      {overlayX: 'start', overlayY: 'top'},
      0, 5
    ),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'bottom' },
      0, -5
    )
  ];
  installmentsMenuIndex: number | null = null;

  isOpenFileMenu: number | null = null;
  pdfLoading: boolean = false;
  
  constructor(private dialog: MatDialog, 
              public transformService: TransformService,
              private deductionsService: DeductionsService, 
              private sharedService: SharedService,
              private rulesService: RulesService) { }

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'issued_by':
          return compare(a.issued_by, b.issued_by, isAsc);
        case 'driver_name':
          return compare(a.driver_name, b.driver_name, isAsc);
        case 'division':
          return compare(a.division, b.division, isAsc);
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'description':
          return compare(a.description, b.description, isAsc);
        case 'total_deductions':
          return compare(a.total_deductions, b.total_deductions, isAsc);
        case 'no_of_installments':
          return compare(a.no_of_installments, b.no_of_installments, isAsc);
        case 'files':
          return compare(a.files.length, b.files.length, isAsc);
        default:
          return 0;
      }
    });
  }

  openDeductionsDialog(element: IDeduction) {
    if(this.permissions[0].sectionArray[1].allowed) {
      let dialogRef = this.dialog.open(AddEditDeductionsDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'component-dialog-container',
        data: {editMode: true, data: element}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          if(result === 'DELETE DEDUCTION') {
            let index: number = this.getIndex(element.id);
            this.dataSource.splice(index, 1);
            this.dataSource = [...this.dataSource];
          }
          else {
            let index: number = this.getIndex(element.id);
            this.dataSource[index] = result;
            this.dataSource = [...this.dataSource];
          }
        }
      });
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  selectFilter(propertyName: string, value: any) {
    this.filters[propertyName] = value;
    this.filters = {...this.filters};
  };

  clearFilters() {
    this.filters = {
      issued_by: [],
      driver_name: [],
      division: [],
      unit_no: [],
      description: 'Both',
      total_deductions: {sign: 'More than', value: null},
      no_of_installments: {sign: 'More than', value: null},
      files: 'Both'
    };
  };

  getIndex(id: number) {
    for(let i = 0; i < this.dataSource.length; i++) {
      if(this.dataSource[i].id === id) {
        return i;
      };
    };
  };
  
  openFileInNewTab(element: any, filename: string) {
    this.getDeductionFiles(element.id, false, filename);
  };

  downloadFile(element: any, filename: string) {
    this.getDeductionFiles(element.id, true, filename);
  };

  getDeductionFiles(id: number, download: boolean, filename: string) {
    this.isOpenFileMenu = null;
    this.pdfLoading = true;
    this.deductionsService.getAllDeductionsFiles(id)
    .pipe(catchError((err: any) => {
      this.pdfLoading = false;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.pdfLoading = false;
      if(download) {
        for(let i = 0; i < response.length; i++) {
          if(response[i].filename === filename) {
            this.sharedService.downloadFile(response[i].data, filename);
            break;
          }
        }
        return;
      }
      for(let i = 0; i < response.length; i++) {
        if(response[i].filename === filename) {
          this.sharedService.downloadPreviewFile(filename, response[i].data);
          break;
        }
      }
    });
  };

  checkUncheckDeduction(element: DeductionInstallements) {
    console.log(element);
    let dialogRef: any = this.dialog.open(ConfirmActionDialogComponent, {
      autoFocus: false,
      panelClass: 'confirm-dialog-container',
      data: 'Are you sure you want to change?'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.deductionsService.checkUncheckDeductionSchedule(element.id, !element.scheduled_checkbox)
        .subscribe((response: boolean) => {
          console.log(response)
          if(response) {
            element.scheduled_checkbox = !element.scheduled_checkbox;
          }
        });
      }
    })
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

}

function compare(a: number | any, b: number | any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
