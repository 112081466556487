import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeductionsService } from '@app/modules/accounting/services/deductions.service';
import { SharedService } from '@app/modules/shared/services/shared.service';

@Component({
  selector: 'app-deduction-dialog',
  templateUrl: './deduction-dialog.component.html',
  styleUrls: ['./deduction-dialog.component.scss']
})
export class DeductionDialogComponent implements OnInit {
  //Deduction group
  deductionFormGroup: any = this._formBuilder.nonNullable.group({
    id: [0],
    issued_by: [null],
    creator_id: [null],
    driver_name: [''],
    driver_id: [0],
    division: [''],
    division_id: [0],
    unit_no: [''],
    description: [''],
    total_deductions: [0],
    no_of_installments: [null],
    date: [null],
    note: [''],
    created_date: [null],
    installements: this._formBuilder.array([]),
    files: this._formBuilder.array([])
  });

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any, 
              public dialogRef: MatDialogRef<DeductionDialogComponent>,
              private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.deductionFormGroup.patchValue({'total_deductions': this.obj.PaymantAmount})
  };

  saveData() {
    this.dialogRef.close(this.deductionFormGroup.value);
  };

  addInstallments(numOfInstallments: any) {
    const enteredNumber: number = Number(numOfInstallments.target.value);
    const array: any[] = this.formArray('installements')?.controls;
    if(enteredNumber > array.length) {
      const length: number = enteredNumber - array.length;
      for(let i = 0; i < length; i++) {
        array.push(this._formBuilder.group({id: null, deduction_id: null, date: null, deducted_amount: null, scheduled_checkbox: false}));
      }
    }
    if(enteredNumber < array.length) {
      const removeCount = array.length - enteredNumber;
      for (let i = 0; i < removeCount; i++) {
        this.formArray('installements').controls.splice(array.length - 1, removeCount)
      }
    };
    this.deductionFormGroup.controls['installements'].updateValueAndValidity();
  };

  //Get form value
  formData(formKey: string) {
    return this.deductionFormGroup.controls[formKey].value;
  };

  //Get form array
  formArray(key: string): FormArray {
    return this.deductionFormGroup.controls[key] as FormArray;
  };

}
