import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ClaimsTableComponent } from './claims-table/claims-table.component';
import moment = require('moment');
import { IClaims } from '../../models/claim.model';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { catchError, Subscription, throwError } from 'rxjs';
import { TitleCasePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClaimTablePipe } from '../../pipes/claim-table.pipe';
import { ClaimsService } from '../../services/claims.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import * as XLSX from 'xlsx';
import { ClaimsDialogComponent } from './claims-dialog/claims-dialog.component';

@Component({
  selector: 'app-claims-page',
  templateUrl: './claims-page.component.html',
  styleUrls: ['./claims-page.component.scss']
})
export class ClaimsPageComponent implements OnInit, OnDestroy {
  @ViewChild(ClaimsTableComponent) childComponent: any;
  permissions: any = this.rulesService.UserData[57].data;

  //Date
  dateObj: any = {
    startDate: '',
    endDate: '',
  };

  //Claims
  claimsArray: IClaims[] = [];

  //Excel config
  xlsxConfig: any[] = [
    {columnName: 'No.', selected: true},
    {columnName: 'Claim#', selected: true},
    {columnName: 'Claim Opened', selected: true},
    {columnName: 'Claim Closed', selected: true},
    {columnName: 'Status', selected: true},
    {columnName: 'Date of Loss', selected: true},
    {columnName: 'Truck#', selected: true},
    {columnName: 'Driver', selected: true},
    {columnName: 'Description of Loss Event', selected: true},
    {columnName: 'Type of claim', selected: true},
    {columnName: 'Claimant', selected: true},
    {columnName: 'Last updated', selected: true}
  ];

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private transformService: TransformService,
              private dialog: MatDialog,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService,
              private titleCase: TitleCasePipe,
              private claimsTablePipe: ClaimTablePipe,
              private claimsService: ClaimsService) { }

  ngOnInit() {
    this.getClaimsTableData();
  };

  getClaimsTableData() {
    this.claimsArray = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('claims-table');
    this.subscription = this.claimsService.getAllClaims(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('claims-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: IClaims[]) => {
      this.claimsArray = response;
      this.spinner.hide('claims-table');
      this.loaded = true;
      console.log(response);
    });
  };

  clearFilters() {
    this.childComponent.clearFilters();
  };

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[47].allowed) {
      const tableData: any[] = this.claimsTablePipe.transform(this.claimsArray, this.childComponent.filters);
      const excelTable: any[] = [];
      for(let i = 0; i < tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, tableData[i].claim, columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, tableData[i].claim_opened, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, tableData[i].claim_closed, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, tableData[i].status, columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.transformDateFormat(tableData[i].date_of_loss, 'MMM DD, YYYY H:mm'), columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, tableData[i].truck_no, columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName,  this.titleCase.transform(tableData[i].driver_no), columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, tableData[i].cd_comments, columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, this.getTypeOfClaim(tableData[i].type_of_claim), columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName, tableData[i].claimant, columnsConfig[10].selected);
        this.transformService.selectedColumn(obj, columnsConfig[11].columnName, this.transformDateFormat(tableData[i].edited_date ? tableData[i].edited_date : tableData[i].created_date, 'MMM DD, YYYY.'), columnsConfig[11].selected);
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'claims.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };
  
  getTypeOfClaim(id: number) {
    const obj: any = {'1': 'Liability – Physical Damage', '2': 'Cargo', '3': 'Physical Damage', '4': 'Liability – Personal Injury',
    '5': 'Liability – Property Damage', '6': 'OCC/ACC'};
    if(id) {
      return obj[id];
    }
    return '';
  };

  openClaimsDialog() {
    if(this.permissions[0].sectionArray[0].allowed) {
      let dialogRef = this.dialog.open(ClaimsDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'component-dialog-container',
        data: {claimId: 0, accident_id: 0, initForm: null}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.getClaimsTableData();
        }
      });
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  transformDateFormat(date: any, format: string) {
    if(date) {
      return moment(date).format(format);
    }
    return '';
  };

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  };

}
