import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Sort } from '@angular/material/sort';
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as XLSX from 'xlsx';

import { NgxSpinnerService } from 'ngx-spinner';
import { DispatchersPerformancesService } from '../../services/dispatchers-performances.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MatDialog } from '@angular/material/dialog';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-driver-performance-table',
  templateUrl: './driver-performance-table.component.html',
  styleUrls: ['./driver-performance-table.component.scss']
})
export class DriverPerformanceTableComponent implements OnInit, OnDestroy {
  @Input() date: any;
  subscription1: Subscription | any;
  subscription2: Subscription | any;
  subscription3: Subscription | any;

  //Metadata
  filterMetaData: any = {
    countObj: {
      gross: 0,
      rate_per_mile: 0,
      mileage: 0,
      margin_percent: 0,
      margin: 0,
      length_per_load: 0,
      gross_per_load: 0,
      loads: 0,
      last_paycheck: 0,
      time_in_company: 0
    }
  };

  compare: boolean = false;

  clickedColumn: string | undefined;
  selectedIndexArray: number[] = [];

  dataSource: any;

  oneRowClickedColumns: boolean[] = [false, false, false, false, false, false, false, false, false, false];

  listByDays: any;

  graphData: any[] = [];

  title: any;

  selectedRow: any;

  driverCardsData: any;

  driverTypesObj: any;

  //Filters

  //Drivers
  filterDrivers: string[] = [];

  //Hometime State
  hometimeStates: string[] = [];

  //Trucks
  filterTrucks: string[] = [];

  //Trailers
  filterTrailers: string[] = [];

  //Status
  filterStatuses: string[] = [];

  //Dispatcher
  filterDispatchers: string[] = [];

  //Recruiter
  filterRecruiters: string[] = [];

  //Type
  filterTypes: string[] = [];

  //Deal
  filterDeals: string[] = [];

  //Num values
  radioBtns: any[] = [
    {name: 'More than'},
    {name: 'Less than'},
    {name: 'Equal'},
  ];

  //Pay
  isOpenPayMenu: boolean = false;
  payValue: string = '';
  paySign: string = 'More than';

  //Gross
  isOpenGrossMenu: boolean = false;
  grossValue: string = '';
  grossSign: string = 'More than';

  //Rate
  isOpenRateMenu: boolean = false;
  rateValue: string = '';
  rateSign: string = 'More than';

  //Mileage
  isOpenMileageMenu: boolean = false;
  mileageValue: string = '';
  mileageSign: string = 'More than';

  //Margin %
  isOpenMarginPercentMenu: boolean = false;
  marginPercentValue: string = '';
  marginPercentSign: string = 'More than';

  //Margin
  isOpenMarginMenu: boolean = false;
  marginValue: string = '';
  marginSign: string = 'More than';

  //Length per load
  isOpenLengthPerLoadMenu: boolean = false;
  lengthPerLoadValue: string = '';
  lengthPerLoadSign: string = 'More than';

  //Gross per load
  isOpenGrossPerLoadMenu: boolean = false;
  grossPerLoadValue: string = '';
  grossPerLoadSign: string = 'More than';

  //Loads
  isOpenLoadsMenu: boolean = false;
  loadsValue: string = '';
  loadsSign: string = 'More than';

  //Last paycheck
  isOpenPaycheckMenu: boolean = false;
  paycheckValue: string = '';
  paycheckSign: string = 'More than';

  //Time in company
  isOpenTimeMenu: boolean = false;
  timeValue: string = '';
  timeSign: string = 'More than';

  //Excel config
  xlsxConfig: any = [
    {columnName: 'No.', selected: true},
    {columnName: 'Driver', selected: true},
    {columnName: 'State', selected: true},
    {columnName: 'Truck', selected: true},
    {columnName: 'Trailer', selected: true},
    {columnName: 'Status', selected: true},
    {columnName: 'Dispatcher', selected: true},
    {columnName: 'Recruiter', selected: true},
    {columnName: 'Type', selected: true},
    {columnName: 'Deal', selected: true},
    {columnName: 'Pay', selected: true},
    {columnName: 'Gross', selected: true},
    {columnName: 'Rate', selected: true},
    {columnName: 'Mileage', selected: true},
    {columnName: 'Margin %', selected: true},
    {columnName: 'Margin', selected: true},
    {columnName: 'Length Per Load', selected: true},
    {columnName: 'Gross Per Load', selected: true},
    {columnName: 'Loads', selected: true},
    {columnName: 'Last Paycheck', selected: true},
    {columnName: 'Time in Company', selected: true}
  ];

  //Interval
  interval: any;

  loaded: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  error: boolean = false;

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  loaded1: boolean = false;
  error1: boolean = false;

  constructor(
    private dispatchersPerformancesService: DispatchersPerformancesService,
    public transformService: TransformService,
    private spinner: NgxSpinnerService,
    private rulesService: RulesService,
    private dialog: MatDialog,
    private titleCase: TitleCasePipe) { }


  ngOnInit() {
    this.spinner.show('driver-p-1');
    this.spinner.show('driver-t-p-1');
    this.getCardsData();
    this.dateChanged();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getCardsData();
        this.getTableData();
      }, this.rulesService.miliseconds);
    }
  }

  ngOnChanges(): void {
    if (this.date) {
      this.getTableData()
    }
  }

  dateChanged() {
    this.subscription2 = this.dispatchersPerformancesService.dateChanged.subscribe((response: any) => {
      this.date.startDate = response.startDate;
      this.date.endDate = response.endDate;
      this.dataSource = undefined;
      this.error1 = false;
      this.loaded1 = false;
      this.spinner.show('driver-t-p-1');
      this.getTableData()
    })
  }

  getCardsData() {
    this.subscription1 = this.dispatchersPerformancesService.getDriversCards(this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('driver-p-1');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.driverCardsData = response;
      this.spinner.hide('driver-p-1');
      this.loaded = true;
    })
  };

  getTableData() {
    this.subscription3 = this.dispatchersPerformancesService.getDriversTableData(this.date.startDate, this.date.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('driver-t-p-1');
      this.loaded1 = true;
      this.error1 = true;
      return throwError(() => err);
    }))
      .subscribe((response: any) => {
        this.reset();
        this.listByDays = response.list;
        this.dataSource = response.total
        this.driverTypesObj = this.transformService.sortByType(response.total);
        this.driverTypesObj['ALL'] = response.total;
        this.spinner.hide('driver-t-p-1');
        this.loaded1 = true;
        console.log(response)
      })
  }

  select(obj: any, key: string, index: number, title: string, oneRowColumn?: any) {
    if (!this.compare) {
      this.selectedRow !== index && this.selectedRow !== undefined ? this.reset() : this.selectedRow = index;
      this.clickedColumn = key;
      this.oneRowClickedColumns[oneRowColumn] = !this.oneRowClickedColumns[oneRowColumn];
      let array: any[] = [];
      this.selectedRow = index;
      for (let key in this.listByDays) {
        let name = this.listByDays[key].name;
        let splitedName = name.split('/')[0].trim();
        if (obj.name == splitedName) {
          array.push(this.listByDays[key]);
        }
      }

      let objForGraph = { key: this.clickedColumn, data: array, title: title, dispatcherName: obj.name, reset: false }
      this.dispatchersPerformancesService.driverTableLineChartSubject.next(objForGraph);
    } else {

      if (this.selectedIndexArray.length === 0) {
        this.clickedColumn = key;
      }

      if (this.clickedColumn === key) {

        let res = this.selectedIndexArray.findIndex((num: number) => num === index);

        if (res === -1) {
          this.selectedIndexArray.push(index);
        } else {
          this.selectedIndexArray.splice(res, 1);
        }
        let array: any[] = [];

        for (let key in this.listByDays) {
          let name = this.listByDays[key].name;
          let splitedName = name.split('/')[0].trim();
          if (obj.name == splitedName) {
            array.push(this.listByDays[key]);
          }
        }

        let objForGraph = { key: this.clickedColumn, data: array, title: title, dispatcherName: obj.name, reset: false }
        this.dispatchersPerformancesService.driverTableLineChartSubject.next(objForGraph);
      }
    }
  }

  setClass(index: number, clickedColumn: string, oneRowIndex?: any) {
    let className: string = '';

    if (!this.compare && this.oneRowClickedColumns[oneRowIndex] && this.selectedRow === index) {
      return className = 'active';
    }

    if (this.selectedIndexArray.includes(index) && clickedColumn === this.clickedColumn) {
      className = 'active';
    }

    return className;
  }

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'hometime_state':
          return compare(a.hometime_state, b.hometime_state, isAsc);
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'trailer_no':
          return compare(a.trailer_no, b.trailer_no, isAsc);
        case 'is_active':
          return compare(a.is_active, b.is_active, isAsc);
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'recruiter':
          return compare(a.recruiter, b.recruiter, isAsc);
        case 'type':
          return compare(a.type, b.type, isAsc);
        case 'deal':
          return compare(a.deal, b.deal, isAsc);
        case 'pay':
          return compare(a.pay, b.pay, isAsc);
        case 'gross':
          return compare(a.gross, b.gross, isAsc);
        case 'rate_per_mile':
          return compare(a.rate_per_mile, b.rate_per_mile, isAsc);
        case 'mileage':
          return compare(a.mileage, b.mileage, isAsc);
        case 'margin_percent':
          return compare(a.margin_percent, b.margin_percent, isAsc);
        case 'margin':
          return compare(a.margin, b.margin, isAsc);
        case 'lengt_per_load':
          return compare(a.lengt_per_load, b.lengt_per_load, isAsc);
        case 'gross_per_load':
          return compare(a.gross_per_load, b.gross_per_load, isAsc);
        case 'loads':
          return compare(a.loads, b.loads, isAsc);
        case 'paycheck':
          return compare(a.paycheck, b.paycheck, isAsc);
        case 'time_in_company':
          return compare(a.time_in_company, b.time_in_company, isAsc);
        default:
          return 0;
      }
    });
  }

  convertDaysToYearsMonthsDays(days: number) {
    const avgDaysInYear = 365;
    const monthLengths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    
    // Calculate years
    const years = Math.floor(days / avgDaysInYear);
    
    // Calculate remaining days
    let remainingDays = days - (years * avgDaysInYear);
    
    // Calculate leap years
    let leapYears = 0;
    for (let i = 0; i <= years; i++) {
        if ((i % 4 === 0 && i % 100 !== 0) || i % 400 === 0) {
            leapYears++;
        }
    }
    
    // Distribute remaining days across months
    let months = 0;
    let currentMonth = 0;
    while (remainingDays > monthLengths[currentMonth]) {
        remainingDays -= monthLengths[currentMonth];
        currentMonth++;
        months++;
        
        if (currentMonth === 1 && leapYears > 0) {
            remainingDays--;
            leapYears--;
        }
    }

    let periodInTheCompany: string = '';
    
    if(years) {
      periodInTheCompany += `${years}${years === 1 ? ' year, ' : ' years, '}`;
    }

    if(months) {
      periodInTheCompany += `${months}${months === 1 ? ' month, ' : ' months, '}`;
    }

    if(remainingDays) {
      periodInTheCompany += `${remainingDays}${remainingDays === 1 ? ' day' : ' days'}`;
    }
    return periodInTheCompany;
  }

  onChange(isTrue: boolean) {
    this.compare = isTrue;
    this.reset();
  }


  //Export to excel
  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[9].allowed) {
      const excelTable: any[] = [];
      for(let i = 0; i < this.filterMetaData.countObj.array.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.titleCase.transform(this.filterMetaData.countObj.array[i].name), columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.filterMetaData.countObj.array[i].hometime_state, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.filterMetaData.countObj.array[i].unit_no, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.filterMetaData.countObj.array[i].trailer_no, columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.filterMetaData.countObj.array[i].is_active, columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, this.titleCase.transform(this.filterMetaData.countObj.array[i].dispatcher), columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, this.titleCase.transform(this.filterMetaData.countObj.array[i].recruiter), columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, this.titleCase.transform(this.filterMetaData.countObj.array[i].type), columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, this.titleCase.transform(this.filterMetaData.countObj.array[i].deal), columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName, this.getPayValue(this.filterMetaData.countObj.array[i]), columnsConfig[10].selected);
        this.transformService.selectedColumn(obj, columnsConfig[11].columnName, `$${this.transformService.addCommasDots(this.filterMetaData.countObj.array[i].gross, 'round')}`, columnsConfig[11].selected);
        this.transformService.selectedColumn(obj, columnsConfig[12].columnName, `$${this.transformService.addCommasDots(this.filterMetaData.countObj.array[i].rate_per_mile)}`, columnsConfig[12].selected);
        this.transformService.selectedColumn(obj, columnsConfig[13].columnName, this.transformService.addCommasDots(this.filterMetaData.countObj.array[i].mileage, 'round'), columnsConfig[13].selected);
        this.transformService.selectedColumn(obj, columnsConfig[14].columnName, `${this.transformService.addCommasDots(this.filterMetaData.countObj.array[i].margin_percent)}%`, columnsConfig[14].selected);
        this.transformService.selectedColumn(obj, columnsConfig[15].columnName, this.transformService.addCommasDots(this.filterMetaData.countObj.array[i].margin), columnsConfig[15].selected);
        this.transformService.selectedColumn(obj, columnsConfig[16].columnName, this.transformService.addCommasDots(this.filterMetaData.countObj.array[i].lengt_per_load, 'round'), columnsConfig[16].selected);
        this.transformService.selectedColumn(obj, columnsConfig[17].columnName, this.transformService.addCommasDots(this.filterMetaData.countObj.array[i].gross_per_load, 'round'), columnsConfig[17].selected);
        this.transformService.selectedColumn(obj, columnsConfig[18].columnName, this.transformService.addCommasDots(this.filterMetaData.countObj.array[i].loads), columnsConfig[18].selected);
        this.transformService.selectedColumn(obj, columnsConfig[19].columnName, this.transformService.addCommasDots(this.filterMetaData.countObj.array[i].paycheck, 'round'), columnsConfig[19].selected);
        this.transformService.selectedColumn(obj, columnsConfig[20].columnName, `${this.filterMetaData.countObj.array[i].time_in_company} days`, columnsConfig[20].selected);
        excelTable.push(obj);
      };
      let footerObj: any = {};
      this.transformService.selectedColumn(footerObj, columnsConfig[0].columnName, `${this.transformService.addCommasDots(this.filterMetaData.countObj.numOfAllDrivers, 'round')}`, columnsConfig[0].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[1].columnName, '', columnsConfig[1].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[2].columnName, '', columnsConfig[2].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[3].columnName, '', columnsConfig[3].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[4].columnName, '', columnsConfig[4].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[5].columnName, '', columnsConfig[5].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[6].columnName, '', columnsConfig[6].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[7].columnName, '', columnsConfig[7].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[8].columnName, '', columnsConfig[8].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[9].columnName, '', columnsConfig[9].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[10].columnName, '', columnsConfig[10].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[11].columnName, `$${this.transformService.addCommasDots(this.filterMetaData.countObj.gross, 'round')}`, columnsConfig[11].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[12].columnName, `$${this.transformService.addCommasDots(this.filterMetaData.countObj.rate_per_mile)}`, columnsConfig[12].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[13].columnName, `${this.transformService.addCommasDots(this.filterMetaData.countObj.mileage, 'round')}`, columnsConfig[13].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[14].columnName, `${this.transformService.addCommasDots(this.filterMetaData.countObj.margin_percent, 'round')}%`, columnsConfig[14].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[15].columnName, `$${this.transformService.addCommasDots(this.filterMetaData.countObj.margin, 'round')}`, columnsConfig[15].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[16].columnName,  `${this.transformService.addCommasDots(this.filterMetaData.countObj.length_per_load, 'round')}`, columnsConfig[16].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[17].columnName, `$${this.transformService.addCommasDots(this.filterMetaData.countObj.gross_per_load, 'round')}`, columnsConfig[17].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[18].columnName, `${this.transformService.addCommasDots(this.filterMetaData.countObj.loads, 'round')}`, columnsConfig[18].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[19].columnName, `$${this.transformService.addCommasDots(this.filterMetaData.countObj.last_paycheck, 'round')}`, columnsConfig[19].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[20].columnName, `${this.transformService.addCommasDots(this.filterMetaData.countObj.time_in_company, 'round') } ${this.filterMetaData.countObj.time_in_company === 1 ? 'day' : 'days' }`, columnsConfig[20].selected);
      excelTable.push(footerObj);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'drivers-table.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  reset() {
    let objForGraph = { key: undefined, data: undefined, title: undefined, dispatcherName: undefined, reset: true };
    this.selectedIndexArray = [];
    this.oneRowClickedColumns = [false, false, false, false, false, false, false, false, false, false];
    this.graphData = [];
    this.clickedColumn = undefined;
    this.dispatchersPerformancesService.driverTableLineChartSubject.next(objForGraph);
  }

  selectFilter(key: string, array: string[]) {
    this[key] = array;
    this[key] = [...this[key]];
  };

  clearFilters() {
    this.filterDrivers = [];
    this.hometimeStates = [];
    this.filterTrucks = [];
    this.filterTrailers = [];
    this.filterStatuses = [];
    this.filterDispatchers = [];
    this.filterRecruiters = [];
    this.filterTypes = [];
    this.filterDeals = [];
    this.payValue = '';
    this.paySign = 'More than';
    this.grossValue = '';
    this.grossSign = 'More than';
    this.rateValue = '';
    this.rateSign = 'More than';
    this.mileageValue = '';
    this.mileageSign = 'More than';
    this.marginPercentValue = '';
    this.marginPercentSign = 'More than';
    this.marginValue = '';
    this.marginSign = 'More than';
    this.lengthPerLoadValue = '';
    this.lengthPerLoadSign = 'More than';
    this.grossPerLoadValue = '';
    this.grossPerLoadSign = 'More than';
    this.loadsValue = '';
    this.loadsSign = 'More than';
    this.paycheckValue = '';
    this.paycheckSign = 'More than';
    this.timeValue = '';
    this.timeSign = 'More than';
  }

  //Select type
  selectType(type: string) {
    let sortedDataArray: any = this.driverTypesObj[type] ? this.driverTypesObj[type] : [];
    this.dataSource = sortedDataArray;
  }

  getPayValue(data: any) {
    if(data.type.toLowerCase() === 'company') {
      return '$'+ this.transformService.addCommasDots(data.pay)
    } 
    else {
      return this.transformService.addCommasDots(data.pay * 100, 'round') + '%';
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
  }

  identify(index, item){
    return item.id; 
  };
  
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
