import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ClaimsService } from '@app/modules/claims/services/claims.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'form-basic-details',
  templateUrl: './form-basic-details.component.html',
  styleUrls: ['./form-basic-details.component.scss']
})
export class FormBasicDetailsComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup;
  @Input() locationsArray: any[] = [];

  //Loads array
  loadsArray: any[] = [];

  //Trucks array
  trucksArray: any[] = [];

  //Trailers array
  trailersArray: any[] = [];

  //Drivers array
  driversArray: any[] = [];

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();
  subscription4: Subscription = new Subscription();

  constructor(private claimsService: ClaimsService,
              private sharedService: SharedService) { }

  ngOnInit(): void {
    this.getLoads();
    this.getAllTrucks();
    this.getAllTrailers();
    this.getAllDrivers();
  }

  getLoads() {
    this.subscription1 = this.claimsService.getAllAccidentLoads()
    .subscribe((response: any) => {
      console.log(response)
      this.loadsArray = response;
      this.loadsArray.unshift({commodity: '', driver: '', driver_id: 0, load_id: 0, load_no: 'Empty', trailer_id: 0,
      trailer_no: '', truck_id: 0, truck_no: ''});
    });
  };

  getAllTrucks() {
    this.subscription2 = this.sharedService.getAllTrucks().subscribe((response: any[]) => {
      this.trucksArray = response;
    });
  };

  getAllTrailers() {
    this.subscription3 = this.sharedService.getAllTrailers().subscribe((response: any[]) => {
      this.trailersArray = response;
    });
  };

  getAllDrivers() {
    this.subscription4 = this.sharedService.getAllDrivers().subscribe((response: any[]) => {
      this.driversArray = response;
    });
  };

  autocomplete(element: any) {
    if(typeof element === 'object') {
      this.formGroup.patchValue({'load_no': element.load_no, 'truck_no': element.truck_no, 'trailer_no': element.trailer_no, 
      'driver_no': element.driver});
    }
    if(typeof element === 'string') {
      this.formGroup.patchValue({'load_no': element});
    }
  };

  autocompleteLocation(element: any, key: string) {
    if(typeof element === 'object') {
      this.formGroup.patchValue({'los_city': element.city, 'los_state': element.state, 'los_zipcode': element.zip_code});
    }
    else {
      this.formGroup.patchValue({[key]: element});
    }
  };

  checkValidation(key: string): boolean {
    return !this.formGroup.controls[key]?.valid && this.formGroup.controls[key]?.touched; 
  };

  //Get form value
  formData(formKey: string) {
    return this.formGroup.controls[formKey].value;
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
  };

}
