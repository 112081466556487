import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'upload-multiple-files',
  templateUrl: './upload-multiple-files.component.html',
  styleUrls: ['./upload-multiple-files.component.scss']
})
export class UploadMultipleFilesComponent {
  @Input() files: any[] = [];
  @Output() triggerEvent = new EventEmitter<any>();

  //Files
  isOpenFilesIndex: number | null = null;

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    ),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'bottom' },
      0, -5
    )
  ];

  //Add files
  onFileSelected(event: any) {
    let length: any = Object.keys(event.target.files).length;
    for (let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName = event.target.files[index].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let base64 = e.target.result.split(',')[1];
      this.triggerEvent.emit({action: 'UPLOAD', data: {filename: fileName, base64: base64}});
    };
    reader.readAsDataURL(event.target.files[index]);
  };

  fileMethod(action: string, data: any) {
    this.isOpenFilesIndex = null;
    this.triggerEvent.emit({action: action, data: data});
  };

}
