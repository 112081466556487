import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountingRoutingModule } from './accounting.routing';
import { SharedModule } from '../shared/shared.module';

import { DashboardAccountingPageComponent } from './components/dashboard-accounting-page/dashboard-accounting-page.component';
import { AccountingTableComponent } from './components/dashboard-accounting-page/accounting-table/accounting-table.component';
import { MaintenanceIftaGraphComponent } from './components/dashboard-accounting-page/maintenance-ifta-graph/maintenance-ifta-graph.component';
import { DashboardInvoicesPageComponent } from './components/dashboard-invoices-page/dashboard-invoices-page.component';
import { InvoicesTableComponent } from './components/dashboard-invoices-page/invoices-table/invoices-table.component';
import { AccountingService } from './services/accounting.service';
import { DashboardAccountingBoardPageComponent } from './components/dashboard-accounting-board-page/dashboard-accounting-board-page.component';
import { DashboardLiabilitiesPageComponent } from './components/dashboard-liabilities-page/dashboard-liabilities-page.component';
import { AssignTrucksDialogComponent } from './components/dashboard-liabilities-page/assign-trucks-dialog/assign-trucks-dialog.component';
import { AssignedTrucksTableComponent } from './components/dashboard-liabilities-page/assigned-trucks-table/assigned-trucks-table.component';
import { AssignedTrucksFilterPipe } from './pipes/assigned-trucks-filter.pipe';
import { AccountingBoardTableComponent } from './components/dashboard-accounting-board-page/accounting-board-table/accounting-board-table.component';
import { AccountingBoardFilterPipe } from './pipes/accounting-board-filter.pipe';
import { FilterAccountingEmployeePipe } from './pipes/filter-accounting-employee.pipe';
import { CreateUpdateNoteDialogComponent } from './components/dashboard-accounting-board-page/accounting-board-table/create-update-note-dialog/create-update-note-dialog.component';
import { DashboardIftaIrpPageComponent } from './components/dashboard-ifta-irp-page/dashboard-ifta-irp-page.component';
import { IftaService } from './services/ifta.service';
import { DashboardPaymentsPageComponent } from './components/dashboard-payments-page/dashboard-payments-page.component';
import { PaymentsTableComponent } from './components/dashboard-payments-page/payments-table/payments-table.component';
import { CreateUpdatePaymentDialogComponent } from './components/dashboard-payments-page/create-update-payment-dialog/create-update-payment-dialog.component';
import { PaymentsService } from './services/payments.service';
import { NestedDropdownComponent } from './components/dashboard-payments-page/create-update-payment-dialog/nested-dropdown/nested-dropdown.component';
import { InvalidValidationDialogComponent } from './components/dashboard-payments-page/create-update-payment-dialog/invalid-validation-dialog/invalid-validation-dialog.component';
import { PaymentTablePipe } from './pipes/payment-table.pipe';
import { PaymentTypeDialogComponent } from './components/dashboard-payments-page/payment-type-dialog/payment-type-dialog.component';
import { PaymentTypePipe } from './pipes/payment-type.pipe';
import { DashboardDeductionsPageComponent } from './components/dashboard-deductions-page/dashboard-deductions-page.component';
import { DeductionsTableComponent } from './components/dashboard-deductions-page/deductions-table/deductions-table.component';
import { AddEditDeductionsDialogComponent } from './components/dashboard-deductions-page/add-edit-deductions-dialog/add-edit-deductions-dialog.component';
import { DeductionsService } from './services/deductions.service';
import { DeductionTablePipe } from './pipes/deduction-table.pipe';
import { DeductionDialogComponent } from './components/dashboard-payments-page/create-update-payment-dialog/deduction-dialog/deduction-dialog.component';

@NgModule({
  declarations: [
    DashboardAccountingPageComponent,
    AccountingTableComponent,
    MaintenanceIftaGraphComponent,
    DashboardInvoicesPageComponent,
    InvoicesTableComponent,
    DashboardAccountingBoardPageComponent,
    DashboardLiabilitiesPageComponent,
    AssignTrucksDialogComponent,
    AssignedTrucksTableComponent,
    AssignedTrucksFilterPipe,
    AccountingBoardTableComponent,
    AccountingBoardFilterPipe,
    FilterAccountingEmployeePipe,
    CreateUpdateNoteDialogComponent,
    DashboardIftaIrpPageComponent,
    DashboardPaymentsPageComponent,
    PaymentsTableComponent,
    CreateUpdatePaymentDialogComponent,
    NestedDropdownComponent,
    InvalidValidationDialogComponent,
    PaymentTablePipe,
    PaymentTypeDialogComponent,
    PaymentTypePipe,
    DashboardDeductionsPageComponent,
    DeductionsTableComponent,
    AddEditDeductionsDialogComponent,
    DeductionTablePipe,
    DeductionDialogComponent
  ],
  imports: [
    CommonModule,
    AccountingRoutingModule,
    SharedModule
  ],
  providers: [AccountingService, IftaService, PaymentsService, DeductionsService]
})
export class AccountingModule { }
