import { TitleCasePipe } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Subscription } from 'rxjs';
import { ConnectionPositionPair } from '@angular/cdk/overlay';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnDestroy {
  
  //Filters array
  filtersArray: any[] = [
    {name: 'Driver', height: 390, type: 2},
    {name: 'Truck', height: 382, type: 0},
    {name: 'Trailer', height: 283, type: 1},
    {name: 'Dispatcher', height: 288, type: 3}
  ];
  isOpenFilterMenu: boolean = false;
  activeCardHeight: number = 390;
  activeFilter: string = 'Driver';
  activeType: number = 2;

  //Search
  searchData: any[] = [];
  isOpenSearchMenu: boolean = false;
  searchText: string = '';

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'center', originY: 'bottom'},
      {overlayX: 'center', overlayY: 'top'},
      0, 10
    )
  ];

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private transformService: TransformService, 
              private sharedService: SharedService, 
              private router: Router,
              private titleCase: TitleCasePipe,
              private clipboard: Clipboard) { 
                this.router.routeReuseStrategy.shouldReuseRoute = function () {
                  return false;
                };
               }

  search() {
    this.subscription = this.sharedService.advancedSearch(this.searchText, this.activeType, this.transformService.filterParams)
    .subscribe((response: any) => {
      this.isOpenSearchMenu = true;
      this.searchData = response;
      console.log(response);
    });
  };

  changeFilter(filter: any) {
    this.activeCardHeight = filter.height;
    this.activeFilter = filter.name;
    this.activeType = filter.type;
    this.resetSearch();
    this.isOpenFilterMenu = false;
  };

  searchProfile(obj: any) {
    let route: string = '';
    if (this.activeFilter === 'Driver') {
      route = `/admin/drivers/filter-drivers/driver-profile/${obj.id}`;
    }; 
    if(this.activeFilter === 'Dispatcher') {
      route = `/admin/dispatchers/dashboard-dispatch-profile-page/${obj.id}`;
    };
    if(this.activeFilter === 'Truck') {
      route = `/admin/trucks/truck-profile/${obj.truck}`;
    };
    if(route) {
      this.router.navigate([route]);
    }
    this.searchText = '';
    this.isOpenSearchMenu = false;
  };

  getHighlightedWord(word: string): string {
    let wordFormatted: string = this.titleCase.transform(word);
    if (!this.searchText) {
      return wordFormatted;
    }
    
    const regex = new RegExp(`(${this.searchText})`, 'gi');
    return wordFormatted.replace(regex, '<span class="fw">$1</span>');
  };

  copyData(data: any) {
    const content: string = `Driver:
${this.titleCase.transform(data.driver)}
Truck:
${data.truck}
VIN#:
${data.vin}
Trailer:
${data.trailer}
Phone:
${data.phone}`;
    this.clipboard.copy(content); 
  };

  //Reset search
  resetSearch() {
    this.searchText = '';
    this.isOpenSearchMenu = false;
    this.searchData = [];
  };

  get dashboardTheme(): boolean {
    if(localStorage.getItem('dashboard-theme')) {
      return JSON.parse(localStorage.getItem('dashboard-theme'));
    }
    return false;
  }

  identify(index, item){
    return item.id; 
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

