import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { catchError, Subscription, throwError } from 'rxjs';
import { TitleCasePipe } from '@angular/common';
import { IDeduction } from '@app/modules/accounting/models/deduction.model';
import moment = require('moment');
import { DeductionsService } from '@app/modules/accounting/services/deductions.service';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';

@Component({
  selector: 'app-add-edit-deductions-dialog',
  templateUrl: './add-edit-deductions-dialog.component.html',
  styleUrls: ['./add-edit-deductions-dialog.component.scss']
})
export class AddEditDeductionsDialogComponent implements OnInit, OnDestroy {
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  fullName: string = `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;
  nickname: string = `${this.loggedUser.nickname}`;
  permissions: any = this.rulesService.UserData[58].data;

  //Deduction group
  deductionFormGroup: any = this._formBuilder.nonNullable.group({
    id: [0],
    issued_by: [`${this.nickname.length === 0 ? this.fullName : this.nickname?.trim().split(' ')[0]}`, [Validators.required, Validators.minLength(2)]],
    creator_id: [this.loggedUser.id],
    driver_name: [''],
    driver_id: [0],
    division: [''],
    division_id: [0],
    unit_no: [''],
    description: [''],
    total_deductions: [0],
    no_of_installments: [null],
    note: [''],
    created_date: [null],
    installements: this._formBuilder.array([]),
    files: this._formBuilder.array([])
  });

  //Drivers
  driversArray: any[] = [];

  //Divisions
  divisionsArray: any[] = [];

  //Units
  unitsArray: any[] = [];

  //Files
  filesLoaded: boolean = true;

  //Multiple clicks
  multipleClicks: boolean = true;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();
  subscription4: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any, 
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<AddEditDeductionsDialogComponent>,
              private sharedService: SharedService,
              private deductionsService: DeductionsService,
              private titleCasePipe: TitleCasePipe,
              private _formBuilder: FormBuilder,
              private rulesService: RulesService) { }

  ngOnInit(): void {
    if(this.obj.editMode) {
      const initData: IDeduction = this.obj.data;
      this.deductionFormGroup.patchValue({
        id: initData.id,
        issued_by: initData.issued_by,
        creator_id: initData.creator_id,
        driver_name: initData.driver_name,
        driver_id: initData.driver_id,
        division: initData.division,
        division_id: initData.division_id,
        unit_no: initData.unit_no,
        description: initData.description,
        total_deductions: initData.total_deductions,
        no_of_installments: initData.no_of_installments,
        note: initData.note,
        created_date: initData.created_date,
      });
      this.initArray('installements', initData.installements);
      this.getDeductionFiles();
    };
    this.getDrivers();
    this.getDivisions();
    this.getTrucksAndTrailers();
  };

  getDrivers() {
    this.subscription1 = this.sharedService.getDriversData().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        response[i].full_name = this.titleCasePipe.transform(`${response[i].first_name} ${response[i].last_name}`);
        response[i].full_name_upper_case = `${response[i].first_name} ${response[i].last_name}`;
      }
      this.driversArray = response;
      this.driversArray.unshift({full_name: 'N/A', full_name_upper_case: 'N/A'});
    });
  };

  getDivisions() {
    this.subscription2 = this.sharedService.getCompanyData().subscribe((response: any) => {
      this.divisionsArray = response.divisions;
      console.log(response)
    });
  };

  getTrucksAndTrailers() {  
    this.subscription3 = this.sharedService.getTrucksAndTrailersData(true).subscribe((response: any) => {
      this.unitsArray = response[0].concat(response[1]);
      this.unitsArray.unshift({unit_no: 'N/A'});
    });
  };

  getDeductionFiles() {
    this.subscription4 = this.deductionsService.getAllDeductionsFiles(this.obj.data.id)
    .pipe(catchError((err: any) => {
      this.filesLoaded = false;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      if(response?.length > 0) {
        this.initArray('files', response);
      }
      this.filesLoaded = true;
    });
  };

  addInstallments(numOfInstallments: any) {
    const enteredNumber: number = Number(numOfInstallments.target.value);
    const array: any[] = this.formArray('installements')?.controls;
    if(enteredNumber > array.length) {
      const length: number = enteredNumber - array.length;
      for(let i = 0; i < length; i++) {
        array.push(this._formBuilder.group({id: null, deduction_id: null, date: null, deducted_amount: null, scheduled_checkbox: false}));
      }
    }
    if(enteredNumber < array.length) {
      const removeCount = array.length - enteredNumber;
      for (let i = 0; i < removeCount; i++) {
        this.formArray('installements').controls.splice(array.length - 1, removeCount)
      }
    };
    this.deductionFormGroup.controls['installements'].updateValueAndValidity();
  };

  multipleUploadMethod(obj: any) {
    if(obj.action === 'UPLOAD') {
      const array: any[] = this.formArray('files')?.controls;
      array.push(this._formBuilder.group({filename: obj.data.filename, data: obj.data.base64}));
      this.deductionFormGroup.controls['files'].updateValueAndValidity();
    };
    if(obj.action === 'NEW TAB') {
      this.sharedService.downloadPreviewFile(obj.data.value.filename, obj.data.value.data);
    };
    if(obj.action === 'DOWNLOAD') {
      this.sharedService.downloadFile(obj.data.value.data, obj.data.value.filename);
    };
    if(obj.action === 'DELETE') {
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.formArray('files').removeAt(obj.data);
          this.deductionFormGroup.controls['files'].updateValueAndValidity();
        }
      })
    };
  };

  saveData() {
    this.deductionFormGroup.controls['installements'].updateValueAndValidity();
    this.deductionFormGroup.controls['files'].updateValueAndValidity();
    this.deductionFormGroup.markAllAsTouched();
    if(this.deductionFormGroup.valid && this.multipleClicks) {
      this.multipleClicks = false;
      const formData: IDeduction = this.deductionFormGroup.value;
      console.log(formData); 
      if(formData.id === 0) {
        formData.created_date = moment().format('YYYY-MM-DDTHH:mm:ss');
      }
      this.deductionsService.createUpdateDeduction(formData).subscribe((response: any) => {
        console.log(response);
        if(response?.id) {
          this.dialogRef.close(response);
        }
        else {
          this.showErrorMessage();
        }
      });
      console.log(formData); 
    }
  };

  deleteDeduction() {
    if(this.permissions[0].sectionArray[2].allowed) { 
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.deductionsService.deleteDeduction(this.obj.data.id, `${this.nickname.length === 0 ? this.fullName : this.nickname?.trim().split(' ')[0]}`, moment().format('YYYY-MM-DDTHH:mm:ss'))
          .pipe(catchError((err: any) => {
              this.showErrorMessage();
              return throwError(() => err);
          }))
          .subscribe((response: any) => {
            console.log(response);
            if(response) {
              this.dialogRef.close('DELETE DEDUCTION');
            }
          });
        }
      })
    }
    else {
      this.showForbiddenMesage();
    }
  };

  //Show error message
  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  //Show forbidden message
  showForbiddenMesage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  initArray(key: string, initArray: any[]) {
    let array: any[] = this.formArray(key)?.controls;
    for(let i = 0; i < initArray.length; i++) {
      const itemGroup = this._formBuilder.group(initArray[i]);
      array.push(itemGroup);
    }
  };

  //Get form value
  formData(formKey: string) {
    return this.deductionFormGroup.controls[formKey].value;
  };

  get getSelectedUnits(): any[] {
    const selectedUnits: string = this.formData('unit_no');
    if(selectedUnits?.length > 0) {
      return selectedUnits.split(', ');
    }
    return [];
  };

  //Get form array
  formArray(key: string): FormArray {
    return this.deductionFormGroup.controls[key] as FormArray;
  };

  checkValidation(key: string): boolean {
    return !this.deductionFormGroup.controls[key].valid && this.deductionFormGroup.controls[key]?.touched; 
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
  };

}
