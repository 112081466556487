import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout, forkJoin, Subject } from 'rxjs';
import * as _G from '../../../_config/globals';
import { INote } from '../models/note-model';
import { ITaskNotes } from '../models/task-notes-model';
import { NotificationsTasksSnackbarComponent } from '../components/notifications-tasks-snackbar/notifications-tasks-snackbar.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class SharedService {  
	headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  headers2 = new HttpHeaders({
		'Content-Type': 'application/json',
	});
	requestOptions2 = { headers: this.headers2 };

  headers3 = new HttpHeaders({
		'Content-Type': 'multipart/form-data',
    'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions3 = { headers: this.headers3};

  httpOptions = {
		headers: new HttpHeaders({
		  'Content-Type': 'application/json',
		  'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy',
		}),
		responseType: 'blob' as 'json',
	};

  //Task notifications data
  taskNotifications: any[] = [];

  constructor(private http: HttpClient, private dialog: MatDialog) { }

  //Status online/offline
  sendStatus(id: number, status: boolean, date: string, history: boolean) {
    this.http.get(`${_G.extendApiUrl}login/online?id=${id}&status=${status}&date=${date}&history=${history}`, this.requestOptions).subscribe((response: any) => {
    });
  };

  getCompanyData() {
		return this.http.get(`${_G.extendApiUrl}Maintenance/getCompanyData`, this.requestOptions);
	}

  sendErrorNotification(error: string, service: string) {
		this.http.get(`${_G.extendApiUrl}reports/reporterror?error=${error}&service=${service}`)
		.subscribe((response: any) => {

		})
	}

  //Company benefit miles
  getCompanyBenefitMiles(startDate: string, endDate: string, activeNum: number, rent: boolean | undefined, filterParams: string) {
    let api: any = '';

    if(rent === undefined) {
      api = this.http.get(`${_G.extendApiUrl}drivers/mileageanalysis?start=${startDate}&end=${endDate}&active=${activeNum}${filterParams}`);
    }
    else {
      api = this.http.get(`${_G.extendApiUrl}drivers/mileageanalysisrest?start=${startDate}&end=${endDate}&rent=${rent}&active=${activeNum}${filterParams}`);
    }
    return api;

  };

  //Quick analysis
  getQuickAnalysisTableData(startDate: string, endDate: string, filterParams: string) {
    return this.http.get(`${_G.extendApiUrl}trucks/emptytrucksanalysis?start=${startDate}&end=${endDate}${filterParams}`);
  };

  //Total loads
  getLoadsTableData(startDate: string, endDate: string, office: string, filterParams: string) {
    let city: string = office === 'All Offices' ? '' : `&city=${office}`;
    return this.http.get(`${_G.extendApiUrl}overview/overviewloads?start=${startDate}&end=${endDate}${filterParams}${city}`);
  };

  changeLoadStatus(loadId: number, status: string) {
    return this.http.get(`${_G.extendApiUrl}loads/setloadstatus?id=${loadId}&status=${status}`, this.requestOptions);
  };

  //Global search
  globalSearchApi(filterParams: string) {
    return this.http.get(`${_G.extendApiUrl}overview/search?${filterParams}`, this.requestOptions);
  };

  //Driver weekly overview
  getDriverWeeklyOveview(startDate: string, endDate: string, driverId: number) {
    return this.http.get(`${_G.extendApiUrl}drivers/driverweeklyoverview?start=${startDate}&end=${endDate}&id=${driverId}`, this.requestOptions);
  };

  //Notification
  getAllNotifications(id: number, numOfNotification: number, type: number) {
    return this.http.get(`${_G.extendApiUrl}notification?id=${id}&number=${numOfNotification}&type=${type}`, this.requestOptions)
  };

  //Open notification
  notificationOpened(id: number) {
    return this.http.get(`${_G.extendApiUrl}notification/opened?id=${id}`, this.requestOptions)
  };

  //Approve notification
  approveNotification(id: number, approve: boolean, approvedBy: number, readedDate: string) {
    return this.http.get(`${_G.extendApiUrl}notification/approve?id=${id}&approve=${approve}&approved_by=${approvedBy}&readed_date=${readedDate}`, this.requestOptions);
  };

  //Loads with margin
  getLoadsWithMargin(startDate: string, endDate: string, filterParams: string) {
    return this.http.get(`${_G.extendApiUrl}loads/loadswithmargin?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
  };

  //Active trucks
  getActiveTrucksTableData() {
    return this.http.get(`${_G.extendApiUrl}trucks/activetrucksoverview`, this.requestOptions);
  };

  getActiveTrucksGraphData(startDate: string, endDate: string, filterParams: string) {
    return this.http.get(`${_G.extendApiUrl}trucks/activetrucksoverviewbyday?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
  };

  changeStatus(truckId: number, status: string) {
    return this.http.get(`${_G.apiUrl}Trucks/settruckstatus?id=${truckId}&status=${status}`);
  };

  statusLog(truckId: number, status: string, createdBy: string, createdDate: string) {
    return this.http.get(`${_G.extendApiUrl}trucks/truckstatuslog?&id=${truckId}&status=${status}&created_by=${createdBy}&created_date=${createdDate}`, this.requestOptions);
  };

  getStatusHistory(id: number, startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}trucks/truckstatushistory?id=${id}&start=${startDate}&end=${endDate}`, this.requestOptions);
  };

  //Rent to run drivers
  getRentToRunDrivers(startDate: string, endDate: string, filters: string) {
    return this.http.get(`${_G.extendApiUrl}overview/renttorun?start=${startDate}&end=${endDate}${filters}`);
  };

  //Top worst dispatchers
  getTopWorstDispatchers(startDate: string, endDate: string, percent: number, status: number, filterParams: string) {
    return this.http.get(`${_G.extendApiUrl}overview/overviewtopworst?start=${startDate}&end=${endDate}&percent=${percent}&active=${status}${filterParams}`, this.requestOptions);
  };

  //Get all dispatchers
  getAllDispatchers() {
    return this.http.get(`${_G.extendApiUrl}dispatcher`, this.requestOptions);
  };

  //Get distance
  getDistance(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}trucks/getDistance`, json, this.requestOptions);
  };

  //Load file
  downloadLoadFile(loadNo: string, fileName: string) {
		let json: any = JSON.stringify({load_no: loadNo, filename: fileName});
    return this.http.post(`${_G.apiUrl}Drivers/getloadfile`, json, this.requestOptions2).pipe(timeout(600000));
	};

  uploadFilesToLoad(formData: any, loadId: number, isConfirmationFile: boolean) {    
    let responseArray: any;
    if(isConfirmationFile) {
      let response1 = this.http.post(`${_G.extendApiUrl}Upload/loads/${loadId}`, formData);
      responseArray = [response1];
    }
    else {
      let response1 = this.http.post(`${_G.apiUrl}Upload/loads/${loadId}`, formData);
      let response2 = this.http.post(`${_G.extendApiUrl}Upload/loads/${loadId}`, formData);
      responseArray = [response1, response2];
    }
    return forkJoin(responseArray);
  };

  getAllFilesLoads(loadNo: string) {
    return this.http.get(`${_G.apiUrl}drivers/getloaddocs?load_no=${loadNo}`);
	};

  //Get all emails
  getAllEmails() {
    return this.http.get(`${_G.extendApiUrl}employee`, this.requestOptions);
  };
  
  //Dispatcher profile 
  sendNotification(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}notification`, json, this.requestOptions);
  };
  

  //Schedule
  getEmailsByDivison(id: number) {
    return this.http.get(`${_G.extendApiUrl}employee/employeesemails?id=${id}`, this.requestOptions)
  };

  //Tickets Warnings reasons
  getTwReasons() {
    return this.http.get(`${_G.extendApiUrl}warnings/reasons`, this.requestOptions);
  };

  saveTwReasons(data: any) {
    let obj: any = {
      id: data.id,
      category: data.category,
      subcategory: data.subcategory,
      is_active: data.is_active,
      created_by: data.created_by,
      created_date: data.created_date,
      edited_by: data.edited_by,
      edited_date: data.edited_date
    }
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}warnings/reasons`, json, this.requestOptions);
  };

  deleteTwReason(ids: number, user: any, date: string) {
    return this.http.get(`${_G.extendApiUrl}warnings/deletereasons?ids=${ids}&id=${user.id}&user=${user.first_name + ' ' + user.last_name}&date=${date}`, this.requestOptions);
  };

  getAllDrivers() {
    return this.http.get(`${_G.extendApiUrl}warnings/drivers`, this.requestOptions);
  };

  //Preview and download files
  downloadPreviewFile(fileKey: string, file: any, pdfDownloadAllowed: boolean = true) {
    const extension = fileKey.split('.').pop().toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg', 'tiff', 'ico'];

    if (extension === 'pdf') {
      //Handle pdf files
      const byteCharacters = atob(file);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      let objectUrl: any = window.URL.createObjectURL(blob);
      const url = pdfDownloadAllowed ? objectUrl : `${objectUrl}#toolbar=0`;
      window.open(url, '_blank');
    } 
    else if (extension === 'doc' || extension === 'docx') {
      // Handle DOC and DOCX files
      const byteCharacters = atob(file);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      let blob;
  
      const extension = fileKey.split('.').pop().toLowerCase();
      if (extension === 'docx') {
        blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      } else if (extension === 'doc') {
        blob = new Blob([byteArray], { type: 'application/msword' });
      } else {
        console.error('Unsupported file extension:', extension);
        return;
      }
  
      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.style.display = 'none';
      anchor.href = URL.createObjectURL(blob);
      anchor.download = fileKey;
      anchor.click();
  
      document.body.removeChild(anchor);
      URL.revokeObjectURL(anchor.href);
    } 
    else if (imageExtensions.includes(extension)) {
      // Handle an image
      let div = document.createElement('div');
      div.style.textAlign = 'center';
      let image = document.createElement('img');
      image.src = `data:image/${extension === 'jpg' ? 'jpeg' : extension};base64,` + file;
      image.style.maxWidth = '100%';
      image.style.maxHeight = '100%';
      div.append(image);
      let w = window.open('');
      w.document.write(div.outerHTML);
    } 
    else {
      // Handle unsupported file extensions
      console.error('Unsupported file extension:', extension);
    }
  }

  downloadFile(base64: string, filename: string) {
    const blob = this.base64ToBlob(base64, 'application/octet-stream'); // Adjust the MIME type as needed
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename; // Set the file name for download
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url); // Clean up the URL object
  };

  base64ToBlob(base64: string, type: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type });
  }

  getAllUsersDepartments() {
    return this.http.get(`${_G.extendApiUrl}employee/dedmpartments`, this.requestOptions);
  };

  //Employee office
  getAllEmployeeOffice() {
    return this.http.get(`${_G.extendApiUrl}employee/cities`, this.requestOptions)
  };

  //Dispatchers
  dataChanged: any = new Subject<any>();
  
  setDispatcherStatus(obj: any) {
    return this.http.post(`${_G.extendApiUrl}dispatcher/dispatcherstatus`, obj);
  };

  createEditDispatcherNote(obj: any) {
		let json: any = JSON.stringify(obj);
		return this.http.post(`${_G.extendApiUrl}dispatcher/editDispatcherNote`, json, {
			headers: this.headers,
			reportProgress: true,
			observe: 'events'
		});
	};

  deleteDispatcherStatus(statusId: number) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/deletestatus?id=${statusId}`);
  };
  
  deleteDispatcherNote(noteId: number) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/deletedispatchernote?id=${noteId}`);
  };

  deleteAllDispatcherNotes(statusId: number) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/deletealldispatchernote?status_id=${statusId}`);
  };

  //Drivers
  statusNoteChangedSubject = new Subject<any>();

  getDriverStatusesByDriver(id: number, startDate: string, endDate: string) {
		return this.http.get(`${_G.extendApiUrl}drivers/driverestatus?id=${id}&start=${startDate}&end=${endDate}`, this.requestOptions);
	};

  getNoteFile(driverId: number, noteId: number, filename: string) {
    return this.http.get(`${_G.extendApiUrl}drivers/driverestatusfile?driver_id=${driverId}&note_id=${noteId}&filename=${filename}`, this.requestOptions);
  };

  setStatus(obj: any) {
		let json: string = JSON.stringify(obj);
		return this.http.post(`${_G.extendApiUrl}drivers/driverestatusbasic`, json, this.requestOptions);
	};

	//Notes
	newNoteSubject: any = new Subject<any>();

	createEditNote(obj: any) {
		let json: any = JSON.stringify(obj);
		return this.http.post(`${_G.extendApiUrl}drivers/editDriverNote`, json, this.requestOptions);
	};

	deleteStatus(status_id: number, deletedBy: string, deletedById: number, date: string) {
		return this.http.get(`${_G.extendApiUrl}drivers/deletestatus?id=${status_id}&deleted_by=${deletedBy}&deleted_by_id=${deletedById}&date=${date}`, this.requestOptions);
	}

	deleteNote(id: number, deletedBy: string, deletedById: number, date: string) {
		return this.http.get(`${_G.extendApiUrl}drivers/deletedrivernote?id=${id}&deleted_by=${deletedBy}&deleted_by_id=${deletedById}&date=${date}`, this.requestOptions);
	};

	deleteAllNote(statusId: number, deletedBy: string, deletedById: number, date: string) {
		return this.http.get(`${_G.extendApiUrl}drivers/deletealldrivernote?status_id=${statusId}&deleted_by=${deletedBy}&deleted_by_id=${deletedById}&date=${date}`);
	};

  getNoteHistory(statusId: number, driverId: number) {
		return this.http.get(`${_G.extendApiUrl}drivers/getdeleteddrivernotes?status_id=${statusId}&driver_id=${driverId}`, this.requestOptions);
	};

  //Drivers activity table pop-ups
  getLoadInfo(driverId: number, date: string, inTransit: boolean) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/loadinfo?id=${driverId}&date=${date}&is_transit=${inTransit}`, this.requestOptions);
  };

  withoutLoadInfo(driverId: number) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/withoutloadinfo?id=${driverId}`, this.requestOptions);
  };

  sendMail(subject: string, body: string) {
    let obj: any = {
      subject: subject,
      body: body
    }
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}dispatcher/needHelp`, json, this.requestOptions);
  };

  getAllLoadNote(loadNo: string) {
		return this.http.get(`${_G.extendApiUrl}drivers/loadsnotes?load_no=${loadNo}`);
	};

  //Live board
  getTruckLocation(unitNo: number, divisionId: number) {
    return this.http.get(`${_G.extendApiUrl}mitf/truckslocation?trucks=${unitNo}&division_id=${divisionId}`, this.requestOptions);
  };

  getAllTrucksLocation() {
    return this.http.get(`${_G.extendApiUrl}mitf/truckslocationall?division_id=-1`, this.requestOptions);
  };

  //Employees
  getAllEmployees() {
    return this.http.get(`${_G.extendApiUrl}employee`, this.requestOptions);
  }

  //All employee departments
  getAllEmployeeDepartments() {
    return this.http.get(`${_G.extendApiUrl}employee/dedmpartments`, this.requestOptions);
  };

  getEmployeeDepartmentsAndCompanies() {
    let response1: any = this.http.get(`${_G.extendApiUrl}employee/dedmpartments`, this.requestOptions);
    let response2: any = this.http.get(`${_G.extendApiUrl}Maintenance/getCompanyData`, this.requestOptions);
    return forkJoin([response1, response2]);
  };

  createUpdateEmployeeDepartment(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}employee/dedmpartments`, json, this.requestOptions);
  };

  deleteEmloyeeDepartment(id: number) {
    return this.http.get(`${_G.extendApiUrl}employee/deletededmpartments?id=${id}`, this.requestOptions);
  };

  
	downloadStatementFile(driverId: number, statementId: number) {
		return this.http.get(`${_G.extendApiUrl}statement/statementfordriver?driver_id=${driverId}&statement_id=${statementId}`, this.httpOptions);
	};

  //Set profile picture
  setPicture(id: number, obj: any) {
    return this.http.post(`${_G.extendApiUrl}dispatcher/dispphoto?id=${id}`, obj, this.requestOptions);
  };

  deletePicture(id: number) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/removeuserphoto?id=${id}`, this.requestOptions);
  };

  //Users
	getUserLogoutStatus(id: number) {
		return this.http.get(`${_G.extendApiUrl}users/islogout?id=${id}`, this.requestOptions)
	};

  //All brokers
  getAllBrokers() {
    return this.http.get(`${_G.extendApiUrl}dispatcher/brokersvalue`, this.requestOptions);
  };

  //Trailers dialog

  //Table data
  getActiveTrailersTableData(filterParams: string) {
    return this.http.get(`${_G.extendApiUrl}trucks/activetrailersoverview?${filterParams}`, this.requestOptions);
  };

  //Graph data
  getActiveTrailersGraphData(startDate: string, endDate: string, filterParams: string) {
    return this.http.get(`${_G.extendApiUrl}trucks/activetrailersoverviewbyday?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
  };

  //Change status
  changeTrailerStatus(trailerId: number, status: string) {
    return this.http.get(`${_G.extendApiUrl}trucks/trailerstatus?trailer_id=${trailerId}&status=${status}`, this.requestOptions);
  };

  //Note
  getNotesByTrailer(trailerNo: number) {
    return this.http.get(`${_G.extendApiUrl}trucks/trailernote?trailer_no=${trailerNo}`, this.requestOptions);
  };

  //Create update trailer note
  createUpdateTrailerNote(obj: INote) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}trucks/trailernote`, json, this.requestOptions);
  };

  //Delete trailer note
  deleteTrailerNote(noteId: number) {
    return this.http.delete(`${_G.extendApiUrl}trucks/trailernote/${noteId}`, this.requestOptions);
  };

  //Trailer location
  getAllTrailerLocations() {
    return this.http.get(`${_G.extendApiUrl}trucks/locationname`, this.requestOptions);
  };

  //Create update trailer location
  createUpdateTrailerLocation(obj: any) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}trucks/locationname`, json, this.requestOptions);
  };

  //Delete trailer location
  deleteTrailerLocation(id: number) {
    return this.http.delete(`${_G.extendApiUrl}trucks/locationname/${id}`, this.requestOptions);
  };

  //Set trailer location
  setTrailerLocation(unitNo: number, location: string) {
    return this.http.get(`${_G.extendApiUrl}trucks/trailerlocation?unit_no=${unitNo}&locatioon=${location}`, this.requestOptions);
  };

  //Get all trucks
  getAllTrucks(){
    return this.http.get(`${_G.extendApiUrl}trucks/truckinfo`, this.requestOptions);
  };
  
  //Get all trailers
  getAllTrailers(){
    return this.http.get(`${_G.extendApiUrl}trucks/trailerinfo`, this.requestOptions);
  };

  //All location
  getAllLocationInfo() {
    return this.http.get(`${_G.extendApiUrl}accident/cities`, this.requestOptions);;
  };

  getCoordinates(addressOrigin: string, addressDestination: string, addressStart?: string) {
    let request1 = this.http.get(`https://nominatim.openstreetmap.org/search?format=json&q=${addressOrigin}`);
    let request2 = this.http.get(`https://nominatim.openstreetmap.org/search?format=json&q=${addressDestination}`);
    let request3 = this.http.get(`https://nominatim.openstreetmap.org/search?format=json&q=${addressStart}`);
    let objToReturn: any = {origin: request1, destination: request2};
    if(addressStart) {
      objToReturn.start = request3;
    }
    return forkJoin(objToReturn);
  };

  getMultipleCoordinates(addressArray: any[]) {
    let requestsObj: any = {};
    for(let i = 0; i < addressArray.length; i++) {
      requestsObj[`request-`+ i] = this.http.get(`https://nominatim.openstreetmap.org/search?format=json&q=${addressArray[i]}`)
    };
    return forkJoin(requestsObj);
  };

  getCurrentAddress(lat: number, lng: number) {
    return this.http.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`);
  };

  //Employee positions
  getAllEmployeePositions() {
    return this.http.get(`${_G.extendApiUrl}employee/positions`, this.requestOptions);
  };

  createUpdateEmployeeStatus(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}employee/employeestatus`, json, this.requestOptions);
  };

  createUpdateVacationStatus(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}employee/employeeholiday`, json, this.requestOptions);
  };

  deleteEmployeeStatus(id: number) {
    return this.http.get(`${_G.extendApiUrl}employee/deleteemployeestatus?id=${id}`, this.requestOptions);
  };

  getFile(employeeId: number, id: number, name: string) {
    return this.http.get(`${_G.extendApiUrl}employee/notefile?employee_id=${employeeId}&id=${id}&name=${name}`, this.requestOptions);
  };

  //Task notes
  getTaskNotes(taskId: number) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/tasknotes?task_id=${taskId}`, this.requestOptions);
  };

  createUpdateTask(array: any[]) {
	  let json: string = JSON.stringify(array);
	  return this.http.post(`${_G.extendApiUrl}dispatcher/tasks`, json, this.requestOptions);
  };

  createUpdateTaskNote(obj: ITaskNotes) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}dispatcher/tasknotes`, json, this.requestOptions);
  };

  deleteTaskNote(noteId: number) {
    return this.http.delete(`${_G.extendApiUrl}dispatcher/tasknotes/${noteId}`, this.requestOptions);
  };

  getTaskNotificationsByDispatcher(id: number) {
    return this.http.get(`${_G.extendApiUrl}notification/tasks?id=${id}`, this.requestOptions);
  };

  showTaskSnackbar(data: any) {
    let dialogRef: any = this.dialog.open(NotificationsTasksSnackbarComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: 'task-notification-overlay',
      data: data
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        for(let i = 0; i < this.taskNotifications.length; i++) {
          if(data.id === this.taskNotifications[i].id) {
            this.taskNotifications.splice(i, 1);
            break;
          };
        };
      }
    })
  };

  //Chat inbox by department
  getInboxDataByDepartment(department: string) {
    return this.http.get(`${_G.extendApiUrl}routingapp/getChatGroupsUser?userDepartman=${department}`, this.requestOptions);
  };

  //Chat inbox dispatch department
  getDriversByDispatcher(dispId: number) {
    return this.http.get(`${_G.extendApiUrl}routingapp/getDriversOfDispatcher?dispID=${dispId}`, this.requestOptions);
  };

  getInboxByDriver(driverId: number) {
    return this.http.get(`${_G.extendApiUrl}routingapp/getChatGroupsUserDispatch?driverID=${driverId}`, this.requestOptions);
  };

  //Chat inbox afterhours department 
  getAfterhoursInbox(groupId: number) {
    return this.http.get(`${_G.extendApiUrl}routingapp/getChatGroupsAfterhours?groupID=${groupId}`, this.requestOptions);
  }

  //Chat send message all departments
  sendMessage(obj: any, department: string, messageToType: string, messageTo: number, dispatcherId: number) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}routingapp/sendChatMessageFromDispatcher?department=${department}&messageToType=${messageToType}&messageTo=${messageTo}&dispatcherID=${dispatcherId}`, json, this.requestOptions);
  };

  getDriversForChat() {
    return this.http.get(`${_G.extendApiUrl}drivers/ptdrivers?active=true`, this.requestOptions);
  };

  getAllSettingsUsers() {
    return this.http.get(`${_G.extendApiUrl}users`, this.requestOptions);
  };

  getAllTrucksData(activeTrucks?: boolean) {
    let param: string = typeof activeTrucks === 'boolean' ? `?active=${activeTrucks}` : ''; 
    return this.http.get(`${_G.extendApiUrl}trucks/pttrucks${param}`, this.requestOptions);
  };

  getTrailersData(activeTrailers?: boolean) {
    let param: string = typeof activeTrailers === 'boolean' ? `?active=${activeTrailers}` : ''; 
    return this.http.get(`${_G.extendApiUrl}trailers/pttailers${param}`, this.requestOptions);
  };

  getTrucksAndTrailersData(activeTrucks?: boolean) {
    let param: string = typeof activeTrucks === 'boolean' ? `?active=${activeTrucks}` : ''; 
    let response1: any = this.http.get(`${_G.extendApiUrl}trucks/pttrucks${param}`, this.requestOptions);
    let response2: any = this.http.get(`${_G.extendApiUrl}trailers/pttailers${param}`, this.requestOptions);
    return forkJoin(response1, response2);
  };

  getDriversData(activeDrivers?: boolean) {
    let param: string = typeof activeDrivers === 'boolean' ? `?active=${activeDrivers}` : ''; 
    return this.http.get(`${_G.extendApiUrl}drivers/ptdrivers${param}`, this.requestOptions);
  };

  assignTruck(driverId: number, truckId: number) {
	  return this.http.get(`${_G.extendApiUrl}drivers/asgndrivertotruck?driver_id=${driverId}&truck_id=${truckId}`, this.requestOptions);
  };

  assignTrailer(trailerId: number, truckId: number) {
	  return this.http.get(`${_G.extendApiUrl}trucks/asgntrailertotruck?trailer_id=${trailerId}&truck_id=${truckId}`, this.requestOptions);
  };

  isTruckTrailerAssigned(id: number, truckOrTrailer: boolean) {
    return this.http.get(`${_G.extendApiUrl}trucks/isassigned?is_assigned=${id}&driver=${truckOrTrailer}`, this.requestOptions);
  };

  advancedSearch(searchText: string, type: number, filters: string) {
    let url: string = `${_G.extendApiUrl}overview/advancedsearch?search=${searchText}&type=${type}${filters}`.replace(/ /g, '%20');
    return this.http.get(url, this.requestOptions);
  };

}
