import { Component } from '@angular/core';
import { RulesService } from '@app/modules/shared/services/rules.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  permissions: any = this.rulesService.UserData[59].data;
  //View btns
  btnActiveIndex: number = 1;

  constructor(private rulesService: RulesService) { }

}
